import React, { useState } from 'react';
import { graphql } from 'gatsby';
import firebase from "gatsby-plugin-firebase"
import Cookies from 'js-cookie'
import { validate } from 'validate.js'

import Layout from '../components/layout'

const ContactPage = ({location, data}) => {
    const [formData, setFormData] = useState({})
    const [formHeadline, setFormHeadline] = useState("Let&apos;s build something<br/> great together.")
    const [formMessage, setFormMessage] = useState("")
    const [errorMessages, setErrorMessages] = useState({})
    
    const url = location.href

    const constraints = {
        email : {
            presence: true,
            email:true
        },

        phone: {
            presence: true
        },

        company: {
            presence: true
        },

        name: {
            presence: true,
            length: {minimum: 4}
        }
    }

    const onInputChange = e => {
        e.persist()
        e.target.classList.add("touched");

        setFormData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const getErrorMessage = (key) => {
        if (key in errorMessages) {
            return <div className="error">{ errorMessages[key][0] }</div>
        }

        return ""
    }

    const onFormSubmit = e => {
        e.preventDefault()

        const errors = validate(formData, constraints)

        if (errors == null || errors.length == 0) {
            var sendContactForm = firebase.functions().httpsCallable('sendContactForm');
            var data = []

            for (const [key, value] of Object.entries(formData)) {
                data.push({
                    "name": key,
                    "value": value
                })
            }

            var cookie = Cookies.get("hubspotutk")

            sendContactForm({ "form": data, "url": url, "cookie": cookie })
            .then(result => {
                if (result.data.success) {
                    var firstName = formData.name.split(" ")[0]

                    setFormHeadline(`Thank you, ${firstName}`)
                    setFormMessage(result.data.result.inlineMessage)
                }
            })
            .catch(error => {
                console.error(error)
            })
        }
        else {
            console.log(errors);
            setErrorMessages(errors);
        }
    }

    const contactForm = (
        <form className="grid" onSubmit={onFormSubmit}>
            <div className="input-container">
                <label htmlFor="name">Name</label>
                <input id="name" name="name" type="text" onChange={onInputChange} required />
                { getErrorMessage("name") }
            </div>
            <div className="input-container">
                <label htmlFor="company">Company</label>
                <input id="company" name="company" type="text" onChange={onInputChange} required />
                { getErrorMessage("company") }
            </div>
            <div className="input-container">
                <label htmlFor="email">Email</label>
                <input id="email" name="email" type="email" onChange={onInputChange} required />
                { getErrorMessage("email") }
            </div>
            <div className="input-container">
                <label htmlFor="phone">Phone</label>
                <input id="phone" name="phone" type="tel" onChange={onInputChange} required />
                { getErrorMessage("phone") }
            </div>
            <div className="input-container">
                <label htmlFor="budget">Project Budget</label>
                <div className="select-container">
                    <select id="budget" name="project_budget" type="text" defaultValue="" onChange={onInputChange} required>
                        <option value="" hidden>Select one</option>
                        <option>$10,000 - $25,000</option>
                        <option>$25,000 - $50,000</option>
                        <option>$50,000 - $100,000</option>
                        <option>$100,000+</option>
                    </select>
                </div>
                { getErrorMessage("project_budget") }
            </div>
            <div className="input-container col-width-full">
                <label htmlFor="comments">What’s your big idea? </label>
                <textarea id="comments" name="comments" onChange={onInputChange} />
                { getErrorMessage("comments") }
            </div>
            <div className="input-container col-width-full" style={{ alignItems: 'center', marginTop: '2rem' }}>
                <input name="submit" type="submit" value="Get in touch!" />
            </div>
        </form>
    )

    const offices = data.allStrapiOffice.nodes

    return (
        <Layout colorTheme="secondary-c" bgColorTheme="bright">
            <main id="hire-us">
                <section className="bright" style={{ paddingBottom: "2rem"}}>
                    <div className="content" style={{ maxWidth: 800 }}>
                        <h1 dangerouslySetInnerHTML={{ __html: formHeadline }} />
                    </div>
                </section>

                <section className="bright" style={{ paddingTop: 0 }}>
                    <div className="content" style={{ maxWidth: 800 }}>
                    {
                        (formMessage == "") ? contactForm : (<div className="message" dangerouslySetInnerHTML={{ __html : formMessage }} />)
                    }
                    </div>
                </section>

                <section className="white">
                    <div className="content column">
                        <h2 className="center" style={{ marginBottom: '2rem', fontWeight: 700 }}>Offices</h2>
                        <div className="grid col-3 mob-wrap" style={{ columnGap: "3rem", rowGap: "3rem"}}>
                            {
                                offices.map((office) => 
                                <div key={ office.id } className="column">
                                    <div className="image-responsive ratio-3-4 round">
                                        <div className="wrapper">
                                            <div className="image" style={{ backgroundImage: `url(${office.Image.publicURL})` }} />
                                        </div>
                                    </div>
                                    <h5 className="big" style={{ marginTop: '1rem',  }}>{ office.Location }</h5>
                                    <p style={{ marginTop: '0.5rem' }}>{ office.Description }</p>
                                    <h5 style={{ paddingTop: '0.5rem' }}>{ office.Subtitle }</h5>
                                    <div style={{ paddingTop: '0.25rem' }}>{ office.Address}</div>
                                    <div style={{ paddingTop: '0.25rem', paddingBottom: '1rem'}}>{ office.StateZIP }</div>
                                    <div>{ office.Email }</div>
                                </div>
                                )
                            }
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export const query = graphql`
  query Contact {
      allStrapiOffice {
          nodes {
              id
              Location
              Address
              Subtitle
              Description
              StateZIP
              Email
              Image {
                  publicURL
              }
          }
      }
  }`

export default ContactPage;